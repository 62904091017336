import * as React from "react"
import Founder from "../components/Founder"
import Demo from "../components/Demo"
import Problems from "../components/Problems"
import Customers from "../components/Customers"
import RoadMap from "../components/RoadMap"
import Structure from "../components/Structure"
import Retool from "../components/Retool"
import Solve from "../components/Solve"
import Head from "../components/Head"
import FAQ from "../components/FAQ"
import ToolsFeature from "../components/ToolsFeature"
import UserCases from "../components/UserCases"
import * as style from './bp.story.css'
// import 'mvp.css'
// import 'charts.css'

const pageStyle = {
  // color: "#232129",
  // paddingBottom: '300px',
  backgroundColor: "rgb(244, 243, 251)"
  // fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const logoStyles = {
  width: "100px"
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyle} id="story">
      <title>Business Plan</title>
      <div className="p-3 ">
        
      
      <header style={{'padding': '2rem 0'}}> <h1>商业计划书</h1> <img src="http://nbimgs.breadtrip.com/8UkomLvO8GUbeU219CpPklShaGXhmXnK/logo.png" style={logoStyles}/> <p className="mt-5"> <small>updated at 2022-04-22</small> </p> </header>
      
      </div>

      <div className="p-3 ">

      <Head></Head>
      </div>
      
      {/* <div className="p-3 ">
      <Structure></Structure>
      </div> */}
      

      {/* <div className="p-3 ">
      <ToolsFeature></ToolsFeature>
      </div>      */}
      
      {/* <div className="p-3">
      <Problems></Problems>
      </div>     
       */}

       <div className="p-3">
       <hr></hr>
      <header> <h1>我们的目标市场</h1>
      <p>通过3个维度来定义市场</p>
      
      </header>

      <div><b>按数据种类划分：</b></div>
      <p>企业的管理数据。例：CRM数据，员工打卡数据，工单流转数据。</p>
      <p>企业的业务数据。例：抖音的视频数据，用户数据，点赞，用户标签等等</p>
      
      <div className="mt-5"><b>按搭建人员划分：</b></div>
      <p>工程师</p>
      <p>业务人员</p>
      
      <div className="mt-5"><b>按使用者区划分：</b></div>
      <p>终端客户</p>
      <p>内部员工（供应链成员）</p>

      <h5 className="mt-5">我们的市场：</h5>
      <p>
        <strong className="red">工程师</strong>搭建企业内部后台，用于
        <strong className="red">企业内部员工</strong>，管理
        <strong className="red">企业业务</strong>数据
      </p>

      <h5 className="mt-5">这使得我们区别于：</h5>
      <p>轻流的市场空间：业务人员搭建，业务人员使用，数据类型为企业管理数据。</p>
      <p>微搭的市场空间：工程师搭建，终端客户使用，数据类型为企业业务数据。</p>

      </div>

      <div className="p-3 ">
      <Solve></Solve>
      </div>     
      

      <div className="p-3">
      <Customers></Customers>
      </div>     

      <div className="p-3">
      <UserCases></UserCases>
      </div>     
        
      <div className="p-3 ">
      <Retool></Retool>
      </div>     

      <div className="p-3 ">
      <Founder></Founder>
        
      </div>     
      <div className="p-3">
      <header> <h1>融资需要</h1> <p>本轮计划出让10%～20%的股权，融资100～200万美金。</p> </header>
      <figure> <img src="http://nbimgs.breadtrip.com/ERERuMvi0ufHC1zpoG123zmjiHiopGIA/funding.jpeg" alt="Funding" /> </figure>
        
      </div>     
      
      <div className="p-3 ">
      <RoadMap></RoadMap>        
      </div>     
      
      <div className="p-3 ">
      <FAQ></FAQ>        
      </div>     
      
      <div className="p-3">
        {/* <a href="http://localhost:3000/">Check Demo List</a>   */}
        <img src="http://nbimgs.breadtrip.com/vOB2MzaOyYIEfeW3bHB7DM9vJ1AzLkEU/%E4%BC%81%E4%B8%9A%E6%95%B0%E6%8D%AE%E6%B5%81%E7%A8%8B%E7%AE%80%E4%BB%8B.png"></img>
        <img src="http://nbimgs.breadtrip.com/2RXQhdnWTbo1AWpUkLWR5IDP8u6YAR1H/%E6%9E%B6%E6%9E%84%E8%AE%BE%E8%AE%A1%202.png"></img>
      </div>     

      <div className="p-3">

      <Demo></Demo>
        
      </div>     

      <div className="p-3">

      <header> <h1>Thanks</h1> </header>
        
      </div>     

      
      

      


      
      
    </main>
  )
}

export default IndexPage
